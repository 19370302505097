.social-login {
  padding-bottom: 20px;
  margin-bottom: 30px;
  //border-bottom: 1px solid #ddd;

  & .button {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 48px;
    font-size: 14px;
    line-height: 48px;
    border-radius: 36px;
    border-style: solid;
    border-width: 1px;
    &_fb {
      background-color: #3b5998;
      border-color: #3b5998;
      float: left;
      span {
        padding: 0 0 0 24px;
        background: url('../images/ico_login_fb.svg') no-repeat left center;
        background-size: 8px 16px;
      }
      &:hover,
      &:focus {
        background-color: #496baa;
        border-color: #496baa;
      }
    }
    &_gp {
      background-color: #dd4b39;
      border-color: #dd4b39;
      float: right;
      span {
        padding: 0 0 0 30px;
        background: url('../images/ico_login_gp.svg') no-repeat left center;
        background-size: 16px 16px !important;
      }
      &:hover,
      &:focus {
        background-color: #ee5b41;
        border-color: #ee5b41;
      }
    }
    span {
      color: #fff;
    }
  }
}
